// Should probably be moved to X common less file

@autocompletePersonImageWidth: 80px;

// Class name should be renamed in new native autocomplete plugin (only remaining that has "ui-" as part of classname)
.ui-helper-hidden-accessible {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: block;
}

body .ac_results {
  background: #fff;
  display: block;
  border: 1px solid #000;
  border-top: none;
  overflow-x: hidden;
  overflow-y: auto;
  width: 240px;
  margin: 0;
  z-index: 2;
  max-width: 500px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    color: #000;
    padding: 12px 20px 12px 20px;
    border-bottom: 1px solid #000;
    overflow: hidden;

    &:before {
      display: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &.ac_over {
      color: #fff;
      background: var(--color-primary);
      border-bottom: 2px solid var(--color-primary);

      .vrtx-person-search-position,
      .vrtx-person-search-tag {
        color: #fff;
      }

      .vrtx-person-search-tag-match {
        background: #000;
      }
    }
  }

  .vrtx-person-search-img {
    float: left;
    clear: left;
    margin: 4px 20px 0 0;
    overflow: hidden;
    width: @autocompletePersonImageWidth;
    height: @autocompletePersonImageWidth; // Square image wrapper
    .centerImageInContainer(50%, 55%);

    img {
      width: @autocompletePersonImageWidth;
    }
  }

  .vrtx-person-search-info {
    &.has-img {
      margin-left: ~"calc(@{autocompletePersonImageWidth} + 20px)";
    }

    > * {
      display: block;
    }
  }

  .vrtx-person-search-tag-match {
    background: var(--color-primary);
    color: #fff;
    padding: 2px 0;
  }

  .vrtx-person-search-tag-match-start {
    padding-left: 4px;
  }

  .vrtx-person-search-tag-match-end {
    padding-left: 4px;
  }
}

.centerImageInContainer(@left: 50%, @top: 50%) {
  position: relative;

  img {
    position: absolute;
    left: @left;
    top: @top;
    transform: translate(-50%, -50%);
  }
}