/*
www.himolde.no x1 local.less

Activate here:
https://www.himolde.no/vrtx/set-design.html
*/

// X-styles
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

@screen-super-oversized: ~"min-width: 3001px";
@screen-insanely-oversized: ~"min-width: 3501px";
:root {
  --color-primary: #1548ED; // HiM strong blue
  --color-primary-dark: #1D3ABD; // HiM strong blue linear
  --color-breadcrumb: #0E257E;
  --color-link: #0E257E;
  --color-button-hover: #1D3ABD;
  --color-white: #FFFFFF; // HiM white
  --color-black: #000000; // HiM black
  --color-lightblue: #D7E1FC; // HiM light blue
  --color-lightblue-hover: #E5EaFD;
  --color-lightblue-air: #EBF0FE;
  --color-darkblue: #0E257E;  // HiM dark blue
  --color-grey: #999999; // HiM grey
  --color-img-text: #707070;
  --color-grey-bg: #f4f4f4; // HiM light blue background
  --color-crisis: #FF4747; // red warning color for warning banner
  --color-info-1: #FCC033; // yellow info banner
  --color-info-2: #9FEED0; // light turquoise info banner
  --space-xxs: 5px;
  --space-xs: 10px;
  --space-xs-s: 15px;
  --space-s: 20px;
  --space-m: 40px;
  --space-l: 80px;
  --space-xl: 120px;
  --border-radius-s: 5px;
  --border-radius-m: 15px;
  --border-radius-l: 100px;

  --img-header-decor: url(../images/header-image-decor.svg);
  --img-footer-bg: url(../images/footer-bg.svg);
  --img-arrow: url("../images/arrow.svg"); //simple blue arrow
  --img-arrow-inv: url("../images/arrow-inv.svg"); //simple white arrow
  --img-arrow-big: url("../images/arrow-big.svg"); //simple big grey arrow used for kontaktveileder
  --img-bullet-arrow: url("../images/bullet-arrow.svg");
  --img-bullet-arrow-big: url(../images/bullet-arrow-big.svg);
  --img-bullet-arrow-big-inv: url(../images/bullet-arrow-big-inv.svg); // White button arrow used in frontpage-header
  --img-bullet-arrow-big-dark: url(../images/bullet-arrow-dark.svg); // White button arrow used in frontpage-header
  --img-button-arrow: url("../images/button-arrow.svg"); // knapp i tidslinje
  --img-button-arrow-inv: url("../images/button-arrow-inv.svg"); // white circle with arrow
  --img-caret: url("../images/caret.svg");
  --img-caret-inv: url("../images/caret-inverse.svg");
  --img-search: url("../images/search.svg");
  --img-search-inv: url("../images/search-inverse.svg");
  --img-menu: url("../images/menu.svg");
  --img-menu-inv: url("../images/menu-inverse.svg");
  --img-bullet-icon: url("../images/bullet-icon.svg");
  --img-location-pin: url("../images/location-pin.svg");
  --img-clock: url("../images/clock.svg");
  --img-calendar: url("../images/calendar.svg");
  --img-caret-bullet: url("../images/caret-bullet.svg");
  --img-info: url("../images/alert.svg");
  --img-quote: url('../images/quote.svg');
  --img-alert: url("../images/warning-triangle.svg");
  --logo: url("../images/logos/logo-him.svg");
  --logo-inv: url("../images/logos/logo-him-inv.svg");
  --logo-eng: url("../images/logos/logo-him-eng.svg");
  --logo-eng-inv: url("../images/logos/logo-him-eng-inv.svg");

  --width-banner-max: 1920px;
  --width-total-max: 1440px;
  --width-max: 1250px;
  --width-soft-max: 1040px;
  --width-text-max: 700px;
}

// Local imports
@import "fonts.less";
@import "subsection.less";
@import "course-listing.less";
@import "exchange.less";

@import "autocomplete.less"; // Should probably be moved to X common less file
                             // (and overrides put in local file, if any)

// Enable background-video
.vrtx-background-video {
  &::before {
    display: none;
  }
  video {
    opacity: 1;
  }
}

@media (@screen-below-medium) {
  .responsive-hide {
    display: none;
  }
}

.gradient-bg-mixin(@start-color, @end-color) {
  background: -webkit-linear-gradient(@start-color, @end-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// x-candidate kontaktveileder
#vrtx-contact-supervisor {
  #vrtx-content {
    .vrtx-contact-supervisor-box {
      margin-bottom: var(--space-m);
      border-bottom: 1px solid var(--color-grey);

      @media (@screen-large) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: var(--space-l);
        justify-content: center;
        align-items: center;
      }
      .vrtx-contact-supervisor-description {
        font-size: 2.4rem;
        font-weight: 700;
        @media (@screen-large) {
          background: var(--img-arrow-big) no-repeat top right;
          padding-right: var(--space-l);
        }
      }
      .vrtx-contact-supervisor-contact-info {
        font-size: 1.8rem;
      }
    }
  }
}

#crisis-banner, #crisis-banner-demo {
  background-color: var(--color-crisis);
  .crisis-banner-inner {
    width: fit-content;
    max-width: 100vw;
    margin: 0 auto;
    padding-left: 50px;
    position: relative;
    &::before {
      background: var(--img-alert) no-repeat center;
      width: 1.7em;
      height: 1.7em;
      top: -2px;
      position: absolute;
      left: 0px;
    }
  }
}

// Remove share at X/twitter
.vrtx-share-at-Twitter {
  display: none;
}

// General

body {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    background-color: var(--color-grey-bg);
}

.main a {
  color: var(--color-link);
}

@media (@screen-medium) {
    h1 {
      font-size: 4.5rem;
      font-weight: 700;
    }
    h2 {
      font-size: 3.4rem;
      font-weight: 700;
    }
    h3 {
      font-size: 2.6rem;
      font-weight: 700;
    }
    h4 {
      font-size: 2.2rem;
      font-weight: 700;
    }
    h5 {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }
  @media (@screen-below-medium) {
    h1 {
      font-size: 3.8rem;
    }
    h2 {
      font-size: 3.1rem;
    }
  }

  .main {
    .read-more,
    .vrtx-more a,
    .vrtx-read-more,
    .all-messages {
      font-weight: 700;
      color: var(--color-black);
      margin-top: var(--space-s);
      &:hover::before {
        left: 5px;
      }
      &::before {
      content: "";
      position: relative;
      background-image: var(--img-bullet-arrow);
      background-size: contain;
      background-color: var(--color-primary);
      border-radius: var(--border-radius-l);
      left: 0;
      padding-right: 1.4em;
      -webkit-transition: 0.2s left ease-in-out;
      -moz-transition: 0.2s left ease-in-out;
      -o-transition: 0.2s left ease-in-out;
      transition: 0.2s left ease-in-out;
    }
  }
}

.link-list li:has(a)::before {
  content: "";
  //background: url(../images/arrow.svg) no-repeat center center;
  top: 6px;
  width: 0.8em;
  height: 0.8em;
}

body.sidebar-menu-wrapper-visible:has(#crisis-banner) .sidebar-menu-wrapper {
  height: fit-content;
 // padding-bottom: 100px;
}
// language select
// on small screen, hide language select
// display it inside main menu when this is open
@media (@screen-below-medium) {
  #header-tools {
    grid-template-columns: auto auto;
    #header-language {
      // faux position inside menu
      position: absolute;
      right: 0;
      top: 101px;
      transition: all .5s;
      > a, > span {
        padding: 4px;
      }
    }
  }

  // hide lang select unless main menu is open
  body:not(.sidebar-menu-wrapper-visible) {
    #header-tools {
      #header-language {
        display: none;
      }
    }
  }

  // when search open, move lang select down
  body.header-search-visible {
    #header-tools {
      #header-language {
        margin-top: 85px;
      }
    }
  }
}

#head-wrapper, .sidebar-menu-wrapper, body:not(.himoldex).sidebar-menu-wrapper-visible #head-admin-menu-wrapper {
  background: var(--Linear, linear-gradient(270deg, var(--color-primary) 2.27%, var(--color-primary-dark) 102.27%));
  color: var(--color-white);
}

#head-admin-menu-wrapper #head-admin-menu {
  margin-right: 0;
}

#header-search-form {
  input[type="text"]::placeholder {
    color: (var(--color-grey));
  }
}

#head-wrapper {
  padding: 5px 0;
  #head {
    @media (@screen-medium) {
      max-width: calc(var(--width-total-max) + 47px);
      padding-left: 10px;
    }
    #header {
      .logo {
        margin-right: 0;
        min-width: 215px;
        background-image: none;
        .title {
          background-image: var(--logo-inv);
          background-repeat: no-repeat;
          width: 236px;
          height: 58px;
          text-indent: -100000px;
          background-size: contain;
          color: black;
        }
      }
      &:has(#section) {
        padding-bottom: 50px;
      }
      #section {
        margin-right: auto;
        position: absolute;
        top: 80px;
        .subsection-title {
          font-size: 3.1rem;
          margin-right: auto;
          text-decoration: none;
          font-weight: 600;
          margin-left: -1px;
          color: var(--color-white);
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
      #header-tools {
        margin-left: auto;
        #header-language, .toggle-search, .sidebar-menu-toggle {
          border-radius: var(--border-radius-s);
        }
        #header-language {
          >a, >span {
            color: var(--color-white);
            font-size: 1.5rem;
          }
          >span {
            border: 3px solid var(--color-white);
            border-radius: var(--border-radius-s);
            padding: 0 4px;
            line-height: 1.5;
          }
        }
        .toggle-search,
        .sidebar-menu-toggle {
          color: var(--color-white);
          font-size: 1.5rem;
          &::before {
            display: none;
          }
          &::after {
            content: "";
            flex-shrink: 0;
            width: 30px;
            height: 20px;
            @media (@screen-medium) {
              margin-left: 10px;
            }
            background-repeat: no-repeat;
            background-position: right center;
            background-size: contain;
          }
        }
        .toggle-search::after {
          background-image: var(--img-search-inv);
          height: 24px;
        }
        .sidebar-menu-toggle::after {
          background-image: var(--img-menu-inv);
        }
      }
    }
  }
}

body.en #head-wrapper #head #header .logo .title {
  background-image: var(--logo-eng-inv);
  width: 250px;
}

.sidebar-menu-wrapper {
  .sidebar-menu {
    background: var(--Liner, linear-gradient(270deg, var(--color-primary) 2.27%, var(--color-primary-dark) 102.27%));
    color: var(--color-white);
  }
  .sidebar-global-menu {
    border: none;
  }
  #supplementary-menu {
    display: block;
  }
}

#vrtx-blog-listing, #vrtx-article-listing, #vrtx-tagview, #vrtx-searchview, body.subsection  {
  h1 {
    margin-top: var(--space-m);
  }
  #breadcrumbs:has(.breadcrumb) + h1 {
    margin-top: 0;
  }
}
body.himoldex.the-frontpage {
  h1 {
    margin-top: var(--space-m);
  }
}


#breadcrumbs {
  font-size: 1.5rem;
  #vrtx-breadcrumb-wrapper {
    margin-bottom: 0;
    &:has(.vrtx-breadcrumb-before-active) {
      padding-top: var(--space-s);
      margin-bottom: var(--space-s);
    }
  }
  a {
    color: var(--color-link);
    text-decoration: none;
  }
  span::after {
    opacity: 1;
  }
  // .vrtx-breadcrumb-active {
  //   display: inline-block;
  // }
}
body.the-frontpage {
  #breadcrumbs .vrtx-breadcrumb-active {
    display: none;
  }
}

// White top
// body:has(.frontpage-top):not(.header-search-visible):not(.sidebar-menu-wrapper-visible) #head-wrapper {
//   background: var(--color-white);
// }
// body:has(.frontpage-top):not(.header-search-visible):not(.sidebar-menu-wrapper-visible), body.himoldex {
//   #head-admin-menu-wrapper {
//     color: var(--color-darkblue);
//   }
//   #head-wrapper {
//     #head #header {
//       .logo .title {
//         background-image: var(--logo);
//       }
//       #header-tools {
//         #header-language {
//           >a, >span {
//             color: var(--color-darkblue);
//             border-color: var(--color-darkblue);
//           }
//         }
//         .toggle-search,
//         .sidebar-menu-toggle {
//           color: var(--color-darkblue);
//         }
//         .toggle-search::after {
//           background-image: var(--img-search);
//         }
//         .sidebar-menu-toggle::after {
//           background-image: var(--img-menu);
//         }
//       }
//     }
//   }
//   &.en {
//     #head-wrapper {
//       #head #header {
//         .logo .title {
//           background-image: var(--logo-eng);
//         }
//       }
//     }
//   }
// }

@media (@screen-large) {
  #hidnav, #head-admin-menu, #head,
  .sidebar-menu-inner-wrapper,
  .mixin-page-wrapper,
  .left-menu-wrapper, .row,
  #vrtx-structured-article #main,
  #vrtx-frontpage #left-main,
  #vrtx-frontpage #breadcrumbs,
  #vrtx-frontpage h1,
  #vrtx-frontpage .vrtx-introduction{
    padding-left: 70px;
  }
  #vrtx-structured-article,
  #vrtx-structured-event {
    #vrtx-content {
      margin-left: 118px;
    }
  }
}

#vrtx-collections {
  display: none;
}

.vrtx-introduction {
  font-size: 2.6rem;
  margin-bottom: var(--space-m);
}
.vrtx-introduction-image {
  margin-bottom: var(--space-m);
}


.main #submenu {
  max-width: var(--width-soft-max);
  //margin: 0 auto;
  .vrtx-subfolder-menu {
    background: var(--color-white);
    border-radius: var(--border-radius-m);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.1);
    padding: var(--space-s) var(--space-s) 0;
    column-width: auto;
    .menu-title {
      font-size: 2.2rem;
      font-weight: 700;
    }
    ul {
      display: flex;
      flex-direction: row;
      column-gap: var(--space-m);
      flex-wrap: wrap;
      li {
        margin-bottom: var(--space-xs);
        min-width: 200px;
      }
    }
    a {
      font-weight: 700;
      gap: var(--space-xs);
      &::before {
        width: 0.9em;;
      }
    }
  }
}

  #vrtx-frontpage #submenu {
    max-width: var(--width-total-max);
    margin: 0 auto;
    @media (@screen-large) {
      padding-left: 65px;
    }
    @media (@screen-below-oversized) {
      padding-right: 50px;
    }
    @media (@screen-below-large) {
      padding-right: 45px;
      padding-left: 50px;
    }
    @media (@screen-below-medium) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  #vrtx-person #submenu {
    max-width: var(--width-total-max);
  }
  #vrtx-structured-event, #vrtx-structured-project {
    #submenu {
      max-width: var(--width-text-max);
    }
  }
  #vrtx-structured-event {
    #submenu {
      margin-left: 130px;
    }
  }


.main {
  ul li {
    &::marker {
      color: var(--color-darkblue);
      font-size: 2.2rem;
      line-height: 1.8rem;
    }
  }
}

#vrtx-frontpage {
  // If box has vrtx-more remove link decoration
  .vrtx-frontpage-box:has(.vrtx-more) h2 a {
      color: var(--color-dark);
      text-decoration: none;
  }
}

//fake button
.main {
  .button, .button-large, .button-loud {
    text-decoration: none;
    font-weight: 700;
    margin: var(--space-xs) 0;
  }
  .button {
    background-color: var(--color-white);
    border-color: var(--color-darkblue);
    color: var(--color-darkblue);
    &::after {
      background-image: var(--img-caret);
    }
  }

  .button-large, .button-loud {
    background-color: var(--color-primary);
    color: white;
    &::after {
      background-image: var(--img-caret-inv);
    }
  }
}

// real button
.main button {
  color: white;
  background-color: var(--color-primary);
  height: 2.8em;
  white-space: nowrap;
  border: none;
  border-radius: var(--border-radius-s);
  padding: 0.7em 1em;
  font-size: 1.8rem;
  &:hover,
  &:focus {
    background-color: var(--color-button-hover);
  }
}

// button in admin-view
.main .vrtx-edit-row  {
  button {
    &:hover, &:focus {
      color: white !important;
    }
  }
}

input[type=text], input[type=search], select {
  height: 3.2em;
  width: 100%;
  padding: 0.5em 2.5em 0.5em 1em;
  margin: var(--space-xs) 0;
  font-size: 1.8rem;
  border: 1px solid var(--color-black);
  border-radius: var(--border-radius-s);
  background-color: var(--color-white);
}

#main .vrtx-search-form, #vrtx-search-container form {
  display: flex;
  position: relative;
  align-items: center;
  max-width: 620px;
}
#vrtx-search-container form {
  max-width: 390px;
}

#vrtx-searchview .vrtx-big-search > div {
  position: relative;
  align-items: center;
  button {
    right: 15px;
  }
}

#vrtx-searchview .vrtx-big-search,
.vrtx-big-search, #main .vrtx-search-form,
#emner , #header-tools .search-form form, #vrtx-search-container {
  .input-button {
    display: flex;
    position: relative;
    align-items: center;
  }
  fieldset {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 620px;
    label {
      white-space: nowrap;
      width: 100%;
    }
    .input-button {
      display: flex;
      position: relative;
      align-items: center;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 3px;
      }
    }
  }
  button {
    background-color: transparent;
    background: var(--img-search) no-repeat center center;
    text-indent: -100000px;
    height: 2.8em;
    width: 2.8em;
    position: absolute;
    right: 0;
    &:hover, &:focus {
      background-color: transparent;
    }
  }
}
#header-tools .search-form form {
  gap: 0;
  position: relative;
  align-items: center;
  input {
    border-color: var(--color-darkblue);
    border-radius: var(--border-radius-s);
    color: var(--color-black);
    &:focus-visible {
      outline: var(--color-darkblue) auto 1px;
    }
  }
  button {
    border: none;
    height: 4.2em;
    width: 3.5em;
  }
}

//header image
#vrtx-frontpage #main {
  .grid-container.full-width-picture-bg {
    margin-bottom: 0;
    padding: 0;
      margin-bottom: 0;
      .vrtx-frontpage-box-picture {
        img {
          margin-left: calc(50% - 50vw + 7px);
          max-width: 100vw;
          object-fit: cover;
        }
      }
  }

  .vrtx-frontpage-box-picture.box-left .vrtx-box-content {
    right: 50%;
    left: unset;
  }

  .grid-container {
    &.media-wide, .media-wide, &.background-video, .background-video {
      &.pos-top img {
        object-position: top;
      }
      &.pos-bottom img {
        object-position: bottom;
      }
      &.pos-left img {
        object-position: top;
      }
      &.pos-right img {
        object-position: right;
      }
      .text-box, .text-box-left {
        border-radius: var(--border-radius-m);
      }
      .text-box-left {
        background: white;
        @media (@screen-large) {
          position: absolute;
          bottom: 50px;
          right: 50%;
          width: 500px;
          padding: 30px;
        }
      }
    }
  }

  .grid-container {
    &.media-wide, &.background-video {
      @media (@screen-large) {
        img, video, .vrtx-frontpage-box {
          min-height: 450px;
        }
      }
      &.frontpage-top {
        //margin-top: -100px;
        padding-bottom: 0;
        .vrtx-frontpage-box.vrtx-frontpage-box-picture {
          display: grid;
          grid-template-rows: 1fr auto;
          align-items: end;
          justify-items: center;
          width: 100%;
          .vrtx-frontpage-box-picture {
            display: grid;
            grid-template-rows: 1fr auto;
            align-items: end;
            justify-items: center;
            width: 100%;
            grid-row: 1 / 3;
            grid-column: 1;
            //max-width: 600px; /* Eksempelbegrensning for container */
            position: relative;
            background-color: var(--color-darkblue);
            &:after {
                content: "";
                background: var(--img-header-decor) no-repeat center top;
                background-size: cover;
                grid-row: 1 / 2;
                grid-column: 1;
                width: 100%;
                max-width: var(--width-banner-max);
                height: 40%;
                position: relative; /* Relativ posisjonering i forhold til bilde */
                z-index: 1; /* Sørger for at overlay ligger over bildet */
                pointer-events: none; /* Gjør overlay "gjennomsiktig" for museklikk */
                display: block;
                box-sizing: border-box;
                @media (@screen-oversized) {
                  height: 40%;
                }
            }
          }
          
          img, video {
            grid-row: 1 / 2;
            grid-column: 1;
            width: 100%;
            max-width: var(--width-banner-max);
            height: 60vh;
            max-height: 700px;
            min-height: 450px;
            margin: 0 auto;
            object-fit: cover;
            @media (@screen-below-medium) {
              min-height: 200px;
            }
          }
          .vrtx-box-content {
            display: flex;
            background-color: transparent;
            z-index: 1;
            width: 100%;
            grid-row: 2 / 3;
            grid-column: 1;
            left: auto;
            bottom: 0;
            position: relative;
            max-height: 150px;
            ul {
              padding: 0;
              @media (@screen-medium) {
                display: flex;
                column-gap: var(--space-m);
                margin: 0 auto;
              }
              li::marker {
                color: transparent;
              }
            }
            a.read-more {
              margin-top: 0;
              color: var(--color-white);
              font-size: clamp(1.8rem, 2.5vw, 2.6rem);
              font-weight: 600;
              @media (@screen-medium) {
                font-size: 4.5rem;
              }
              &::before {
                background-image: var(--img-button-arrow-inv);
                background-color: transparent;
                margin-right: var(--space-s);
                background-size: 1em;
              }
            }
          }
        }
      }
      img {
        height: 60vh;
      }
    }
  }
}
#vrtx-frontpage.sidebar-menu-wrapper-visible {
    #main .grid-container {
      &.media-wide, &.background-video {
        &.frontpage-top {
          margin-top: 0;
        }
      }
    }
  }


// #vrtx-frontpage:has(#head-admin-menu-wrapper) #main .grid-container.frontpage-top {
//   margin-top: -140px;
// }
// #vrtx-frontpage:has(#head-admin-menu-wrapper):has(#crisis-banner) #main .grid-container.frontpage-top {
//   margin-top: -100px;
//   @media (@screen-below-medium) {
//     margin-top: -70px
//   }
// }
// hiding image outside box in admin view
// body#vrtx-frontpage.vrtx-mode-edit #main .grid-container.media-wide.frontpage-top .column-wrapper {
//   overflow: hidden;
// }

#vrtx-frontpage.vrtx-mode-edit, #vrtx-frontpage.vrtx-mode-preview {
  #main .grid-container {
      &.media-wide img, &.background-video img {
        height: auto;
      }
    }
  }

// Push top img down when menu is open
#vrtx-frontpage {
  &.sidebar-menu-wrapper-visible, &.vrtx-mode-edit, &.header-search-visible,
  &.sidebar-menu-wrapper-visible:has(#head-admin-menu-wrapper) {
  #head-wrapper #head #header #header-tools {
    #header-language, .toggle-search, .sidebar-menu-toggle {
      background-color: transparent
    }
  }
  #main .grid-container.frontpage-top {
    &.media-wide, &.background-video {
      .vrtx-frontpage-box-picture {
        img, video {
          margin-top: 0;
        }
      }
    }
  }
}
}
img.user-round-image {
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

@media (@screen-medium) {
  .grid-container .vrtx-box-content {
     border-radius: var(--border-radius-m);
  }
  .column-wrapper.half-half {
    grid-template-columns: 1fr 1fr;
  }
  .column-wrapper.triple {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.row-all-colored .vrtx-frontpage-box {
  &.box-special {
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  }
}

.row-all-colored .vrtx-frontpage-box, .vrtx-frontpage-box {
  &.img-special-left,
  &.img-special-right,
  &.box-special {
    border-radius: var(--border-radius-m);
    .vrtx-box-content {
      padding: var(--space-l);
      font-size: 2.6rem;
      @media (@screen-below-medium) {
        padding: var(--space-m);
        padding-top: var(--space-l);
      }
      h2 {
        font-size: 3.4rem;
      }
      a.read-more {
        color: var(--color-black);
        font-size: 1.8rem;
      }
    }
  }
}

#vrtx-frontpage {
  #main .grid-container {
    background-color: var(--color-white);
    padding: var(--space-l) 0;
    @media (@screen-below-medium) {
      padding: var(--space-m) 0;
    }
    margin-bottom: 0;
    &.row-all-colored, &:has(.img-special-right), &:has(.img-special-left) {
      padding: var(--space-l) 0;
    }
    &.row-all-colored + &.row-all-colored {
      padding: 0 0 var(--space-l) 0
    }
    &.media-wide, &.background-video {
      padding: 0;
      .vrtx-box-content {
        @media (@screen-medium) {
          padding-bottom: var(--space-s);
        }
        @media (@screen-below-large) {
          p {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}

.grid-container, .row-all-colored {
  .vrtx-frontpage-box.img-special-right,
  .vrtx-frontpage-box.img-special-left {
    background: transparent;
    .vrtx-frontpage-box-picture {
      z-index: 1;
    }
    .vrtx-box-content {
      background-color: var(--color-lightblue);
      border-radius: var(--border-radius-m);
      box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
      @media (@screen-large) {
        height: 90%;
      }
      @media (@screen-below-medium) {
        border-start-end-radius: 0;
        border-start-start-radius: 0;
        margin: -40px var(--space-s) var(--space-m);
      }
    }
    img {
      aspect-ratio: 10 / 8;
    }
  }
  .vrtx-frontpage-box.img-special-right {
    .vrtx-box-content {
      @media (@screen-medium) {
        padding-right: 60%;
        margin-right: -50%;
      }

    }
  }
  .vrtx-frontpage-box.img-special-left {
    .vrtx-box-content {
      @media (@screen-medium) {
        padding-left: 60%;
        margin-left: -50%;
      }
    }
    .vrtx-frontpage-box-picture {
      img {
        z-index: 2;
      }
    }
  }
}

#vrtx-frontpage {
  #main .grid-container.row-all-colored {
    background-color: var(--color-grey-bg);
    .vrtx-frontpage-box.img-special-right,
    .vrtx-frontpage-box.img-special-left {
      background: transparent;
      .vrtx-box-content {
        background-color: white;
      }
    }
  }
}


.vrtx-frontpage-box.link-special {
  background: var(--color-white);
  border: none;
  border-radius: var(--border-radius-m);
  padding: var(--space-l) var(--space-xl);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  .vrtx-box-content {
    font-size: 4.5rem;
    font-weight: 700;
    a {
      color: var(--color-black);
      &::after {
        background-image: var(--img-bullet-arrow);
        background-size: 50px auto;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        margin-top: 0;
      }
    }
  }
}

.vrtx-imagetext, figcaption {
  font-size: 1.5rem;
  color: var(--color-img-text);
}

.three-column-list,
.two-column-list {
  .vrtx-box-content {
    ul, ol {
      column-gap: var(--space-m);
      column-count: 1;
      li {
        break-inside: avoid;
      }
    }
  }
}
.three-column-list .vrtx-box-content {
  ul, ol {
    @media (@screen-medium) {
      column-count: 3;
    }
  }
}
.two-column-list .vrtx-box-content {
  ul, ol {
    @media (@screen-medium) {
      column-count: 2;
    }
  }
}

details.accordion-wrapper {
  background: var(--color-white);
  margin-bottom: var(--space-s);
  border-radius: var(--border-radius-m);
  border: 1px solid var(--color-darkblue);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  padding: var(--space-s) var(--space-m);
  height: fit-content;
  summary {
      margin-bottom: 0;
      width: 100%;
      overflow: visible;
      h2.accordion {
          font-size: 2.6rem;
          margin: 0;
          &:after {
            top: 0.2em;
          }
      }
      h3.accordion {
          font-size: 2.2rem;
          &:after {
            top: 0em;
          }
      }
      h4.accordion {
          font-size: 1.8rem;
      }
      .accordion {
          width: auto;
          padding: 0px 2em 0px 0;
          margin-bottom: 0;
          color: var(--color-sort);
          &::before {
              display: none;
          }
          &::after {
            content: "";
            background: var(--img-caret-bullet) no-repeat;
            right: 0;
            height: 27px;
            top: 0.5em;
            position: absolute;
            width: 27px;
            background-size: 27px;
          }
      }
      &:hover {
          text-decoration: none;
          text-decoration-color: transparent ;
      }
      p {
          padding-bottom: 20px;
      }
  }
  &[open] summary .accordion::after {
    content: "";
    background: var(--img-caret-bullet) no-repeat;
    background-size: 27px;
    rotate: 180deg;
  }
  .accordion-content {
    padding: var(--space-s) var(--space-l) var(--space-m) 0;

  }
}
.accordion-end {
  margin-top: 15px;
}

.navigation-links li a::after {
  background: var(--img-caret) no-repeat center;
  background-size: 75%;
  width: 1.5em;
}

#main {
  .row-all-colored .navigation-links,
  .navigation-links {
    margin-bottom: var(--space-s);
    ul {
      gap: 20px;
      li {
        padding-bottom: 0;
        p {
          display: grid;
        }
        a {
          padding-right: 2em;
          font-size: 1.8rem;
          font-weight: 700;
          background-color: var(--color-lightblue);
          border-width: 1px;
          //If text is set in strong, the other text should have weight 400 while strong should have 600
          &:has(strong) {
            font-weight: 400;
            font-size: 1.8rem;
          }
          strong {
            font-weight: 600;
            font-size: 2.2rem;
          }
          &:hover,
          &:focus {
            border-color: var(--color-darkblue);
          }
          &::after {
            background: var(--img-caret) no-repeat center;
            background-size: 100%;
          }
        }
      }
    }
    &.grey-box ul li a {
      background-color: white;
    }
  }
}

#main .row-all-colored,
#vrtx-fs-studieprogram-frontpage #main {
  .navigation-links ul li a {
    background-color: white;
    &:hover,
    &:focus {
      border-color: var(--color-darkblue);
    }
  }
}

blockquote {
  margin: var(--space-m) var(--space-s) var(--space-s);
  padding-left: 52px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 2.5em;
    left: 0;
    background: var(--img-quote) no-repeat;
  }
  p:first-child {
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: var(--space-xs);
  }
  p:last-child {
    padding-bottom: var(--space-s);
    font-size: 1.5rem;
  }
}

.vrtx-article-body iframe.nettskjema-iframe, iframe.nettskjema-iframe {
  height: 100vh;
}

#main {
  .vrtx-article-body .vrtx-facts-container,
  .vrtx-facts-container {
    background: var(--color-white);
    font-size: var(--font-size-small);
    color: var(--color-black);
    padding: 20px;
    margin: var(--space-m) auto;
    border-radius: var(--border-radius-m);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
    ul li::marker {
      color: var(--color-black);
      font-size: 1.8rem;
    }
    h2 {
      font-size: 2.6rem;
      font-weight: 700;
      &:first-child {
        margin-bottom: var(--space-s);
      }
    }
    *:last-child {
      margin-bottom: var(--space-xs);
    }
    &.vrtx-container-right {
      margin-left: var(--space-m);
    }
    &.vrtx-container-left {
      margin-right: var(--space-m);
    }
  }
}

// Project
#vrtx-structured-project {
  #vrtx-content {
    @media (@screen-large) {
      padding-right: 110px;
      padding-left: 110px;
    }
  }
}

// Project-listing
#vrtx-project-listing, #vrtx-research-group-listing {
  .main {
     >ul , ul.vrtx-alphabetical-research-group-listing {
      padding-left: 0;
      font-size: 2.8rem;
         li {
             &::marker {
                 color: transparent;
             }
         }
         ul {
          font-size: 1.8rem;
             list-style-type: disc;
             li::marker {
                 color: var(--color-darkblue);
             }
         }
     }
     .vrtx-resources {
      @media (@screen-medium) {
        grid-template-columns: repeat(auto-fit,minmax(425px, 1fr));
      }
    }
  }
}

//Person-listing
#vrtx-tags-container {
  margin: var(--space-m) 0;
  #vrtx-tags {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-xs);
    ul {
      display: flex;
      gap: var(--space-xs);
      flex-wrap: wrap;
      margin-bottom: auto;
      padding-left: 0;
      li {
        background-color: var(--color-white);
        padding: var(--space-xs);
        border-radius: var(--border-radius-m);
        white-space: nowrap;
        height: fit-content;
        &::marker {
          color: transparent;
        }
      }
    }
  }
}

#main table.vrtx-person-listing {
  tbody {
    tr {
      &:nth-child(odd) {
        background-color: var(--color-white);
      }
    }
    .vrtx-person-listing-position, .vrtx-person-listing-name a:not(.vrtx-image) {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--color-darkblue);
    }
    .vrtx-person-listing-name {
      background: url(../images/no-portrait.svg) no-repeat 25px 25px;
      background-size: 135px;
      padding-bottom: 80px;
      padding-left: 180px;
      margin: 20px;
      &:has(.vrtx-image) {
        background: none;
      }
      .vrtx-image {
        max-height: 135px;
        max-width: 135px;
        width: 135px;
        border: none;
        top: 25px;
        left: 25px;
        img {
          border-radius: var(--border-radius-l);
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
  @media (@screen-below-large) {
    tbody {
      tr {
        padding: var(--space-s);
        display: flex;
        flex-direction: column;
      }
      .vrtx-person-listing-name {
        padding: 0;
        margin: 0;
        padding-top: 160px;
        background-position: 0 0;
      }
    }
  }
}

// Person

#vrtx-person-contact-info-wrapper {
  background-color: var(--color-white);
  border-radius: var(--border-radius-m);
  padding: var(--space-m);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-m);
  row-gap: var(--space-s);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
  .vrtx-person-image-wrapper {
   background: url(/vrtx/dist/resources/x1/images/no-portrait.svg) no-repeat;
    background-size: 200px;
    height: 200px;
    padding-left: 200px;
    margin-bottom: var(--space-m);
    @media (min-width: 900px) {
      margin: var(--space-m);
    }
    &:has(.vrtx-person-image) {
      background: none;
      padding-left: 0;
      @media (min-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .vrtx-person-contactinfo {
    h1 {
      font-size: 3.6rem;
      font-weight: 700;
      margin-bottom: var(--space-xs);
    }
    .vrtx-unit {
      font-size: 2.6rem;
      font-weight: 700;
      display: block;
    }
    #vrtx-hiof-person-change-language-link {
      display: none;
    }
    .person-contact-info-grid {
      margin-top: var(--space-s);
      @media (@screen-medium) {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: var(--space-xs);
      }
  }
    .vrtx-person-contactinfo .vrtx-label {
      display: inline-block;
      font-weight: 700;
    }
  }
  .vrtx-person-image {
    max-width: 200px;
    max-height: 200px;
    min-width: 200px;
    min-height: 200px;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: var(--border-radius-l);
  }
}

.vrtx-tags {
  margin-bottom: var(--space-m);
  .title {
    font-weight: 400;
  }
  .vrtx-tags-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-top: var(--space-s);
    a {
      background: var(--color-white);
      padding: var(--space-xs) var(--space-s);
      border-radius: var(--border-radius-l);
    }
  }
  .tag-separator {
    display: none;
  }
}

#vrtx-tags {
  column-count: 3;
}

#vrtx-publication-tabs > ul a {
  height: 2.9em;
  padding: var(--space-xs-s);
  padding-top: 12px;
  border-color: var(--color-darkblue);
}
#vrtx-publication-tabs > ul a[aria-expanded=true] {
  background-color: var(--color-darkblue);
  color: white;
}
@media (@screen-large) {
  #vrtx-publication-tabs > ul {
    background-color: var(--color-grey-bg);
  }
}
ul.vrtx-external-publications li {
  background-color: var(--color-white);
  margin-bottom: var(--space-s);
  padding: var(--space-s);
  border-radius: var(--border-radius-m);
}
.vrtx-publication-summary a {
  height: 2.9em;
  border-color: var(--color-darkblue);
  &::before {
    display: none;
  }
  &.vrtx-publication-summary.expanded::after {
    transform: rotate(270deg);
    transition: all 0.3s;
  }
  &::after {
    content: "";
    flex-shrink: 0;
    background: url(../images/chevron.svg) no-repeat center;
    transform: rotate(90deg);
    background-size: contain;
    height: 1em;
    width: 10px;
    transition: all 0.3s;
  }
}

#vrtx-person-main-content-wrapper {
  margin-top: var(--space-m);
}


// Project
#vrtx-structured-project {
  .vrtx-comp-research-group-leader {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--space-s);
    h2 {
      grid-column-start: 1;
      grid-column-end: 3;
      display: inherit;
    }
    .vrtx-person-image {
      grid-row-start: 2;
      grid-row-end: 6;
      border-radius: var(--border-radius-l);
      aspect-ratio: 1 / 1;
      min-width: 200px;
      max-width: 200px;
      object-fit: cover;
    }
    .vrtx-label {
      display: none;
    }
    .vrtx-value {
      height: min-content;
    }
  }
}


//article
.vrtx-introduction-image img {
  border-radius: var(--border-radius-m);
}

// article-listing, event-listing
.vrtx-feed .vrtx-image img {
  border-radius: var(--border-radius-m);
}
.three-column-feed, .two-column-feed {
  .vrtx-image img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (min-width: 751px) {
  .featured-feed-item .vrtx-feed li:first-child .vrtx-image img {
      border-bottom-left-radius: var(--border-radius-m);
      border-top-right-radius: 0;
  }
}

#vrtx-article-listing, #vrtx-event-listing {
  .main a.vrtx-title {
    color: var(--color-black);
  }
  .vrtx-image img {
    border-radius: var(--border-radius-m);
  }
}

[class*=column-feed] {
  .vrtx-feed li {
    background: white;
    border-radius: var(--border-radius-m);
    box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
    .item-title, .authors, .published-date, .item-description {
      padding: 0 var(--space-s);
      p {
        margin-bottom: var(--space-s);
      }
    }
    .vrtx-list-item-content {
      margin-top: var(--space-s);
    }
  }
}


#main {
  .vrtx-event-component {
    align-items: start;
    .vrtx-event-component-item {
      display: flex;
      align-items: center;
      column-gap: var(--space-s);
      .vrtx-event-component-title {
        color: var(--color-black);
      }
      .vrtx-date-icon,
      .vrtx-daily-events-date {
        background: var(--Liner, linear-gradient(270deg, var(--color-primary) 2.27%, var(--color-primary-dark) 102.27%));
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        width: 100px;
        min-width: 100px;
        height: 100px;
        color: var(--color-white);
        font-size: 1.8rem;
        .vrtx-date-icon-day {
          font-weight: 600;
          font-size: 2.2rem;
          &.vrtx-date-icon-today {
            font-size: 2.2rem;
          }
        }
        .vrtx-date-icon-month {
          font-size: 1.8rem;
        }
        &:has(.vrtx-date-icon-end) {
          flex-direction: column;
          .vrtx-date-icon-day {
            font-size: 1.8rem;
          }
          .vrtx-date-icon-separator {
            padding-bottom: 0;
            line-height: 0.8;
          }
          .vrtx-date-icon-end {
            display: flex;
            flex-direction: row;
            column-gap: 0.2em;
            font-size: 2rem;
            .vrtx-date-icon-day {
              font-weight: 600;
              font-size: 1.8rem;
            }
          }
        }
      }
      .grey-box {
        .vrtx-date-icon,
        .vrtx-daily-events-date {
          background: white;
        }
      }
    }
    .vrtx-event-component-item.with-picture {
      margin-top: 20px;
      background-color: white;
      padding: var(--space-xs);
      border-radius: var(--border-radius-m);
      box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.10);
      .vrtx-date-icon {
        right: var(--space-xs);
        bottom: var(--space-s);
        margin-bottom: 0;
      }
      .vrtx-list-item-content {
        padding-right: 110px;
      }
    }
  }
  .vrtx-time-and-place-container {
    .time-and-place {
      display: none;
    }
    .dtstart::before, .location::before, .vrtx-add-event::before {
      content: "";
      display: inline-block;
      width: 22px;
      height: 22px;
      background-size: contain;
      padding-right: var(--space-xs);
      background-repeat: no-repeat;
      top: 4px;
      position: relative;
    }
    .dtstart::before {
      background-image: var(--img-clock);
    }
    .location {
      display: block;
      &::before {
        background-image: var(--img-location-pin);
        height: 30px;
        top: 7px;
      }
    }
    .vrtx-add-event::before {
      background-image: var(--img-calendar);
    }
  }
}

@media (@screen-large) {
  #vrtx-structured-event {
    .vrtx-introduction-image, .vrtx-article-body, .vrtx-byline {
      max-width: var(--width-text-max);
      margin-left: 130px;
    }
    .vrtx-byline {
      margin-bottom: 0;
    }
    .vrtx-time-and-place-container {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      font-size: 1.6rem;
      font-weight: 700;
      .location {
        margin: 0 var(--space-s)
      }
    }
  }
}

#vrtx-blog-listing, #vrtx-structured-article.blog {
  h1 {
    display: none;
  }
  #main .grid-container.full-width-picture-bg {
    margin-bottom: var(--space-m);
    @media (@screen-medium) {
      .vrtx-box-content {
        margin-top: -120px;
        a {
          color: #fff;
        }
      }
    }
    .vrtx-box-content {
      a {
        font-size: 45px;
        font-weight: 600;
        text-decoration: none;
      }
    }
    .row {
      padding: 0;
    }
  }

  .grid-container.blog-about {
    max-width: 100vw;
    width: 100vw;
    margin-left: calc(50% - 50vw - 8px);
    .vrtx-frontpage-box {
      &.img-special-left, &.img-special-right {
        background-color: transparent;
        .vrtx-frontpage-box-picture {
          margin-top: auto;
          margin-bottom: auto;
          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
    @media (@screen-below-medium) {
      width: 100%;
      margin-left: 0;
      background-color: transparent;
    }
    .row {
      padding-left: 0;
      padding-right: 0;
    }
  }
  div#vrtx-content {
    .vrtx-paging-feed-wrapper {
      margin-bottom: var(--space-m);
    }
  }
  div#vrtx-additional-content {
    width: 270px;
  }
}
// Blog ^

 //contact component
.vrtx-contact-component {
  // Var used to calculate sizes based on image-width

  @media (@screen-below-medium) {
    --img-width: 100px;
    padding-left: 0;
    img, &:not(:has(img))::before {
      position: relative;
      margin-bottom: var(--space-s);
    }
  }
  @media (@screen-medium) {
    --img-width: 200px;
    padding-left: calc(var(--img-width) + 25px);
    img, &:not(:has(img))::before {
      position: absolute;
      margin: 0; // Override for when used in article-body
      top: 50%;
      transform: translateY(-50%);
    }
  }
  position: relative;
  min-height: var(--img-width); // Aspect ratio 1/1, so height = width
  margin-top: 15px;
  margin-bottom: var(--space-s);
  img, &:not(:has(img))::before {
    background-color: #0001;
    left: 0; //calc(calc(var(--img-width) + 25px) * -1);
    width: var(--img-width);
    max-width: fit-content;
    min-width: 200px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 100%;
  }
  .name,
  .title {
    font-variation-settings: 'wght' 600;
  }
  .name {
    font-size: 2rem;
    line-height: 1.3;
    @media (@screen-large) {
      padding-top: 10px;
    }
  }
  .title {
    @media (@screen-large) {
      margin-bottom: 10px;
    }
  }
  .contact {
    margin: 0;
  }
}

.people-list-columns {
   .vrtx-box-content {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     column-gap: var(--space-m);
     row-gap: var(--space-s);
     .vrtx-contact-component {
       max-width: 450px;
     }
     @media (@screen-below-large) {
      flex-direction: column;
     }
   }
}

// .vrtx-contact-info ^


// facts-table
#vrtx-frontpage #main .grid-container.row-all-colored:has(.facts-table) {
  @media (@screen-below-medium) {
    padding: var(--space-m) 0;
  }
}
.facts-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: var(--space-m);
  div {
    display: flex;
    flex: 1;
    justify-content: unset;
    align-items: center;
    text-align: center;
    flex-direction: column;
    min-width: 125px;
    span {
      &:nth-of-type(2) {
        font-weight: 700;
        font-size: 4rem;
      }
      @media (@screen-below-medium) {
        font-size: 1.4rem;
        &:nth-of-type(2) {
          font-size: 2rem;
        }
      }
    }
  }
}

// table
th[class*="sortable-"] a {
  float: left;
}

// steps-guide
#main {
  .steps-guide {
    width: 100%;
    counter-reset: step;
    margin: 20px 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    &>li {
      flex: 1;
      background-color: var(--color-lightblue);
      color: var(--color-darkblue);

      border-radius: 5px;
      align-self: stretch;
      list-style-type: none;
      padding: 15px 20px 20px 20px;
      clip-path: polygon(calc(100% - 10px) 0,calc(100% - 10px) calc(50% - 10px), 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(50% + 10px), 10px 50%, 0 calc(50% - 10px), 0 0);
      &:first-child {
        clip-path: polygon(calc(100% - 10px) 0,calc(100% - 10px) calc(50% - 10px), 100% 50%, calc(100% - 10px) calc(50% + 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(50% + 10px), 0 calc(50% - 10px), 0 0);
      }
      &:last-child {
        clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 calc(50% + 10px), 10px 50%, 0 calc(50% - 10px), 0 0);
      }

      >a, >span {
        text-decoration: none;
        &::before {
          counter-increment: step;
          content: counter(step);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 20px calc(50% - 30px);
          background-color: var(--color-primary);
          border-radius: 100%;
          color: white;
          font-weight: 700;
          line-height: 2.1;
          width: 3em;
          height: 3em;
        }
      }
      a:focus, a:hover {
        text-decoration: underline;
      }
    }
    ul li {
      list-style-type: disc;
    }
  }
  .grid-container.row-all-colored .steps-guide >li {
    background-color: var(--color-white);
  }
}
// steps-guide ^

// Timeline
.vrtx-article-body .timeline,
.vrtx-frontpage-box.timeline {
  padding-bottom: 75px;
  .vrtx-box-content, .timeline-inner {
    &:before {
      border-bottom: none;
    }
    > div {
      background-color: var(--color-lightblue);
      color: var(--color-darkblue);
      border-radius: var(--border-radius-m);
      border-top-left-radius: 0;
    }
    > div::before {
      background-color: transparent;
      transform: none;
      width: 103%;
      height: auto;
      left: 26px;
      top: -58px;
      border-bottom: 2px dashed var(--color-darkblue);
    }
    > div:last-child::before {
      border-bottom: none;
    }
    > div::after {
      background-color: var(--color-primary);
      border: 2px solid var(--color-primary);
      width: 20px;
      height: 20px;
    }
  }
  .prev, .next {
    background-image: var(--img-button-arrow);
    background-color: transparent;
    background-size: 50px;
    width: 50px;
    height: 50px;
  }
  .prev {
    transform: rotate(180deg);
  }
}
.row-all-colored .vrtx-frontpage-box.timeline .vrtx-box-content > div::after {
  background-color: var(--color-primary);
}
// Timeline ^

// study finder
body#vrtx-fs-studieprogram-finder {
  #main {
    background-color: var(--color-lightblue);
  }
   #vrtx-main-content {
    background-color: var(--color-lightblue);
  }
  #study-program-finder {
    .studies-feedback {
      background-color: transparent;
      color: var(--color-darkblue);
      padding-left: 0;
      margin-bottom: 0;
    }

    .studies-filter {
      .expand-filters {
        color: var(--color-darkblue);
        align-items: center;
      }
      h2 {
        color: var(--color-darkblue);
        font-size: 2.2rem;
        font-weight: 700;
      }
      .button-filter label {
        border-radius: var(--border-radius-l);
        background-color: var(--color-lightblue);
        font-size: 1.8rem;
        font-weight: 700;
        border-color: transparent;
        //padding: var(--space-xs) var(--space-s);
        &.selected, &:hover {
          background-color: var(--color-darkblue);
          color: var(--color-white);
        }
      }
      select {
        border-radius: var(--border-radius-l);
        border-color: var(--color-darkblue);
        font-size: 1.8rem;
        font-weight: 700;
        height: 2.4em;
      }
      input {
        border-radius: var(--border-radius-s);
        border-color: var(--color-darkblue);
        font-size: 1.8rem;
      }
      .text-filter {
        color: var(--color-darkblue);
        border-radius: var(--border-radius-m);
      }
    }
    .inner {
      color: var(--color-darkblue);
      border-radius: var(--border-radius-m);
    }
    .studies-list {
      li:has(.no-applicants) a {
        background-color: var(--color-lightblue);
        border-color: var(--color-darkblue);
        border-radius: var(--border-radius-m);
      }
      a {
        border-radius: var(--border-radius-m);
      }
      img {
        height: auto;
        width: 120px;
        align-self: stretch;
        @media (@screen-medium) {
          border-top-right-radius: var(--border-radius-m);
          border-bottom-right-radius: var(--border-radius-m);
        }
      }
    }
  }

  #footer-wrapper {
    // background in footer on studiesfilter
    background-color: var(--color-lightblue);
  }
}
// studies-filter ^

// studies
#vrtx-fs-studieprogram-frontpage #main {
  @media (@screen-medium) {
    h1, h2, h3, h4, h5, h6 {
      text-align: center;
    }
  }

  .vrtx-introduction-image {
    img {
      max-width: var(--width-total-max);
      margin-left: -50px;
      width: 100vw;
      height: 50vh;
      object-fit: cover;
      border-radius: 0;
      @media (@screen-below-medium) {
        margin-left: -25px;
      }
    }
    .vrtx-imagetext {
      display: none;
    }
  }
  #program-intro {
    text-align: center;
    background: var(--color-grey-bg);
    max-width: var(--width-max);
    border-radius: var(--border-radius-m);
    padding: var(--space-m);
    margin: var(--space-m) auto;
    position: relative;
  }
  &:has(.vrtx-introduction-image) #program-intro {
    margin: -80px auto;
  }
  .vrtx-article-body {
    margin: 0 auto;
    border-bottom: none;
  }
}

.left {
  padding-right: 20px;
  float: left;
  width: 50%;
}

#vrtx-fs-studieprogram-plans, #vrtx-fs-studieprogram-stories, #vrtx-fs-studieprogram-contact {
  margin: 0 auto;
  max-width: var(--width-text-max);
  .vrtx-more {
    text-align: center;
  }
}
#vrtx-fs-studieprogram-contact, #vrtx-fs-studieprogram-stories {
  margin-top: var(--space-m);
}

#vrtx-fs-studieprogram-search-button p {
  display: flex;
  justify-content: center;
  max-width: var(--width-max);
  .button {
    background: var(--color-primary);
    color: var(--color-white);
    border-color: transparent;
    margin-top: -68px;
    margin-bottom: 25px;
    &::after {
      background-image: var(--img-caret-inv);
  }
  }
}

// Program-status
.vrtx-context-message-box.grid-container {
  margin-bottom: var(--space-m);
  .row {
    padding: 0;
    .content-wrapper {
      padding: var(--space-s);
      background: #FFD465 var(--img-info) no-repeat var(--space-s) center;
      max-width: var(--width-max);
      margin: 0 auto;
      .message-text {
        margin-left: 55px;
      }
    }
  }
}

#main {
  #vrtx-fs-studieprogram-facts, #vrtx-fs-emne-facts {
    h4 {
      display: none;
    }
    &.vrtx-facts-container {
      background-color: var(--color-lightblue);
      color: var(--color-darkblue);
      border-radius: var(--border-radius-m);
      max-width: var(--width-max);
      padding: var(--space-m);
      padding-bottom: var(--space-xs);
      z-index: 0;
      position: relative;
      @media (max-width: 750px) {
        margin: var(--space-m) 0px;
      }
      dl {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          row-gap: 20px;
          column-gap: 60px;
          .dg {
              display: flex;
              flex-direction: column;
              flex-wrap: nowrap;
              ul {
                padding-left: 0;
                li {
                    padding-top: 0;
                    &::marker {
                        color: transparent;
                    }
                }
            }
              dt {
                white-space: nowrap;
              }
          }
      }
      h2 {
          display: none;
          font-size: 2.0rem;
          margin-bottom: 0;
      }
      dd, dt {
          display: inline;
          color: var(--color-korall-dark);
      }
      dt {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
      dd {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 125%; /* 22.5px */
        &:after {
          content: "";
        }
      }
      ul li::marker {
        color: var(--color-darkblue);
        font-size: 1.8rem;
      }
    }
  }
}

#vrtx-fs-studieprogram-frontpage {
  #vrtx-fs-studieprogram-contact {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -100px;
    align-items: center;
    padding-bottom: var(--space-l);
    column-gap: var(--space-l);
    h2 {
      flex: 100%;
    }
    .image {
      max-width: 150px;
      img {
        border-radius: var(--border-radius-l);
        aspect-ratio: 1 / 1;
        object-fit: cover;
        max-height: 150px;
      }
    }
    @media (max-width: 800px) {
      .info {
        flex: 100%;
        margin-right: 0;
        .contact {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
#vrtx-fs-studieprogram-document {
  @media (@screen-medium) {
    .fs-body, .vrtx-context-box-linked {
      max-width: var(--width-text-max);
    }
    #vrtx-content {
      margin: 0 0 0 150px;
      max-width: var(--width-soft-max);
    }
    .vrtx-context-box-linked {
      margin: 0 0 0 150px;
    }
  }
  .vrtx-context-box-linked {
    margin-bottom: var(--space-xs);
    a {
      color: black;
      font-size: 2.2rem;
      font-weight: 600;
      text-decoration: none;
    }
  }
  #toc {
    color: var(--color-darkblue);
    ul li ul {
      display: none;
    }
  }
}

#vrtx-fs-studieprogram-document, #vrtx-fs-emne {
  #vrtx-content {
    @media (@screen-large) {
      max-width: var(--width-text-max);
    }
  }
  #vrtx-main-content {
    .vrtx-fs-study-model {
      .course-list {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: calc(var(--space-s) + var(--space-xs));
          margin-top: var(--space-s);
          li {
              position: relative;
              background-color: var(--color-lightblue);
              margin-bottom: var(--space-xs);
              padding: 0;
              border-radius: var(--border-radius-m);
              &:hover, &:focus {
                background-color: var(--color-lightblue-hover);
              }
          }
          li:not(.mandatory){
              .course-code{
                  &:after{
                      content: "\00a0 / Valgbart emne";
                      display: inline-block;
                      color: var(--color-black);
                  }
              }
          }
          .course-description{
            display: flex;
            flex-direction: column;
            row-gap: var(--space-xs);
              .course-code {
                  font-weight: 700;
                  color: var(--color-black);
              }
              .course-info {
                  display: block;
                  color: var(--color-link);
              }
              .course-terms {
                  order: 3;
                  color: var(--color-black);
                  &:before{
                      content: "\00a0 /\00a0";
                  }
              }
          }
          .course-study-points{
              display: flex;
              align-items: center;
              justify-content: center;
              min-width: 4.5em;
              width: 4.5em;
              height: 4.5em;
              color: #fff;
              background: var(--color-darkblue);
              border-radius: var(--border-radius-l);
              margin-left: auto;
              span:first-child{
                  font-weight: 700;
              }
              span:first-child:after {
                  content: "\00a0";
              }
          }
          .course-link, .course-not-linked {
            display: flex;
            align-items: center;
            column-gap: var(--space-xs-s);
            padding: var(--space-xs-s);
            .course-description {
              &:hover {
                background-color: var(--color-neutral--light);
                .course-info {
                  text-decoration: underline;
                }
              }
            }
          }
          .course-not-linked {
            .course-not-linked-info {
              color: var(--color-black);
              display: block;
            }
          }
      }
      form[id*="direction-form-"] {
        max-width: fit-content;
        label {
          background-color: var(--color-lightblue);
          width: 100%;
          border-radius: var(--border-radius-s);
          color: var(--color-black);
          &:hover, &:focus {
            background-color: var(--color-lightblue-hover);
          }
          &::before {
            box-shadow: 0 0 0 2px var(--color-darkblue);
          }
        }
        input:focus + label {
          outline: 2px solid var(--color-darkblue);
          outline-offset: 2px;
        }
        input:checked + label {
          background-color: var(--color-darkblue);
          color: white;
          &::before {
            background-color: var(--color-darkblue);
          }
        }
      }
    }
  }
}
// emne
#vrtx-fs-emne{
  #main {
    .vrtx-facts-container {
      margin: 0;
    }
  }
  .fs-emne-content-wrapper {
    margin-top: var(--space-m);
    max-width: var(--width-text-max);
  }
}

// studies ^

.vrtx-paging-wrapper a {
  color: var(--color-black);
}

#global-sub-menu {
  display: none;
}

#footer-wrapper {
  margin-bottom: 0;
  padding: 0 0 120px;
  color: white;
  font-size: var(--font-size-small);
  background: var(--color-darkblue);
  &::before {
    content: "";
    background-color: var(--color-primary);
    max-width: var(--width-banner-max);
    height: 100px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: block;
    top: 0; 
    //clip-path: polygon(0 100%, 69% 0, 100% 42%);
    clip-path: polygon(0 0, 100% 0, 100% 40%, 0% 100%);
}
  @media (@screen-below-large) {
    background-position-x: right;
  }
  #footers {
    padding: var(--space-s) var(--space-s) var(--space-m) var(--space-s);
    border-bottom: 1px solid var(--color-primary);
    background-color: var(--color-darkblue);
    .footer-content {
      position: relative;
      #footer-logo {
        background: var(--logo-inv) no-repeat;
        position: absolute;
        bottom: -120px;
        height: 52px;
        width: 215px;
        text-indent: -10000px;
        background-size: contain;
        @media (@screen-below-medium) {
          bottom: -250px;
        }
      }
    }
  }
}
body.en #footer-wrapper {
  #footers {
    .footer-content {
      #footer-logo {
        background-image: var(--logo-eng-inv);
      }
    }
  }
}