// Nunito

// Italic
//@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 200 1000;
  font-display: swap;
  src: url('/vrtx/dist/resources/x1/fonts/nunito-italic.woff2') format('woff2');
 }

 @font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
  src: url('/vrtx/dist/resources/x1/fonts/nunito-normal.woff2') format('woff2');
 }
