// himolde exchange.less

#vrtx-uh-exchange-listing {
  @media (@screen-large) {
    #vrtx-main-content {
      padding-left: 38%;
      &::after {
        content: "";
        display: block;
        clear: left;
      }
    }
    h1,
    #filters-form,
    .vrtx-introduction {
      margin-left: -61%;
    }
    h1 {
      margin-bottom: 35px;
    }
    .vrtx-introduction {
      margin-top: -10px;
    }
    #filters-form {
      position: sticky;
      top: -15px;
      float: left;
      width: 55%;
      // Safety-valve
      max-height: 100vh;
      overflow: auto;
    }
  }
  #filters-form {
    background-color: var(--color-lightblue);
    border-radius: var(--border-radius-m);
    padding: 25px;
    fieldset {
      max-width: var(--width-text-max);
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      legend {
        margin-bottom: 5px;
        color: var(--color-darkblue);
      }
      ul {
        background-color: var(--color-lightblue-air);
        //font-size: var(--font-size-small);
        border: 1px solid #0004;
        border-radius: var(--border-radius-m);
        padding: 10px 15px;
        margin: 0;
        overflow: auto;
        scrollbar-color: #0008 transparent;
        // scrollbar-width: thin;
      }
      li {
        width: auto;
        label {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    input:hover,
    input:hover + label,
    label:hover,
    button:hover {
      background-color: white;
    }
  }
  #vrtx-main-content {
    // Only show active filters heading (span) if there are active filters,
    // to prevent showing "No active filters".
    span.active-filters {
      display: none;
    }
    &:has(ul.active-filters) {
      span.active-filters {
        display: revert;
      }
    }
  }

  .studies-feedback {
    background-color: transparent;
    color: var(--color-darkblue);
    padding-left: 0;
    margin-bottom: 0;
  }
  .vrtx-exchange-destination-entry {
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--space-s);
    a {
      width: 100%;
      font-weight: 700;
      font-size: 2.6rem;
    }
    .country, .agreement, .type {
      flex-shrink: 0;
      color: var(--color-darkblue);
    }
}
}
// Exchange list page filter
#vrtx-uh-exchange-listing {
  #filters-form {
    #vrtx-filter-type {
      margin-bottom: 0;
    }
    ul {
      max-height: 195px; // Set so text is cut in half
    }
    #vrtx-filter-agreement {
      display: none; // Hide filter for avtale (Erasmus etc)
    }
    // Submit button not in use. Submits on checkbox click, using JS.
    button[type=submit] {
      display: none;
    }
  }
}

#vrtx-uh-exchange-destination {
  #vrtx-content {
    max-width: var(--width-total-max);
    .vrtx-uh-infobox {
      .vrtx-introduction-image {
        img {
          max-width: var(--width-total-max);
          margin-left: -50px;
          width: 100vw;
          //height: 50vh; // Moved to avoid vh units in vrtx-preview. Kjetil Oct. 2024
          object-fit: cover;
          border-radius: 0;
          @media (@screen-below-medium) {
            margin-left: -25px;
          }
        }
      }
    }
    .vrtx-uh-content, .vrtx-field-stories, .vrtx-field-contact, .vrtx-field-programmes {
      max-width: var(--width-text-max);
      margin: 0 auto;
    }
  }
  // Image height set with exception for vortex preview
  &:not(.vrtx-mode-preview) {
    #vrtx-content {
      max-width: var(--width-total-max);
      .vrtx-uh-infobox {
        .vrtx-introduction-image {
          img {
            height: 50vh;
          }
        }
      }
    }
  }
  .vrtx-uh-facts-horizontal {
    dt, dd {
      display: inline-block;
    }
    dt {
      font-weight: 700;
    }
  }

  #main:has(.vrtx-introduction-image) #program-intro {
    margin: -80px auto;
  }
  #program-intro {
    text-align: center;
    background: var(--color-grey-bg);
    max-width: var(--width-max);
    border-radius: var(--border-radius-m);
    padding: var(--space-m);
    margin: var(--space-m) auto;
    position: relative;
  }
  #main .vrtx-facts-container {
    background-color: var(--color-lightblue);
    color: var(--color-darkblue);
    border-radius: var(--border-radius-m);
    max-width: var(--width-max);
    padding: var(--space-m);
    padding-bottom: var(--space-xs);
    z-index: 0;
    position: relative;
  }
}