#vrtx-fs-emne-listing {
  #vrtx-listing-filters {
    background-color: var(--color-lightblue);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media (@screen-below-large) {
      grid-template-columns: 1fr;
    }

    padding: var(--space-s);
    border-radius: var(--space-xs-s);
    gap: 30px 0;

    h2 {
      color: var(--color-darkblue);
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 26.4px */
      margin-bottom: var(--space-xs-s);
    }

    // Reset lists in filters
    ul, li {
      display: block;
      margin: 0;
      padding: 0;
    }

    li {
      margin-bottom: var(--space-xs);
    }

    a {
      position: relative;
      padding-left: 35px;
      color: #000;
      text-decoration: none;

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 5px;
        border: 1px solid var(--color-darkblue);
        background: #FFF;
      }
    }

    li.vrtx-listing-filter-parameter-selected {
      a {
        &:before {
          background: var(--color-darkblue) url("../images/checked-inverse.svg") no-repeat center center;
          background-size: auto 50%;
        }
      }
    }

    .vrtx-listing-filters-section-single.vrtx-listing-filters-section-last {
      border-top: 1px solid #000;
      padding-top: var(--space-s);
      grid-column: 1 / -1;
    }
  }

  #vrtx-listing-filter-no-results,
  #vrtx-listing-filter-hits {
    font-size: 2.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 39px */
    color: var(--color-darkblue);
    margin: 55px 0 35px 0;
  }

  .vrtx-fs-emne-result {
    max-width: 750px; // var(--width-text-max);
    margin-bottom: var(--space-xs);
    padding: var(--space-s);

    border-radius: var(--space-xs-s);
    background: var(--color-lightblue);

    h3 {
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 31.2px */
      margin-bottom: 0;
    }
  }
}